<template>
	<div class="performance performance--overview">
		<div class="performance__col performance__col--2">
			<number-bars class="number-bars--red" :title="`Average \nDaily Value of \nEquities Trades \nCleared`" unit="( in millions )" :numbers="dailyValueEquities" />

			<number-bars class="number-bars--red" :title="`Average Daily \nVolume of \nEquities Trades \nCleared`" unit="( in millions )" :numbers="dailyVolumeEquities" />
		</div>
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'EquitiesClearing',
	components: {
		NumberBars,
	},
	data: () => ({
		dailyValueEquities: [
			{
				value: 1697.6,
				label: '2020',
				options: {
					prefix: '$',
					decimalPlaces: 1,
				},
			},
			{
				value: 1181.40,
				label: '2019',
				options: {
					prefix: '$',
					decimalPlaces: 1,
				},
			},
			{
				value: 1269.70,
				label: '2018',
				options: {
					prefix: '$',
					decimalPlaces: 1,
				},
			},

		],
		dailyVolumeEquities: [
			{
				value: 173.4,
				label: '2020',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 106.5,
				label: '2019',
				options: {
					decimalPlaces: 1,
				},
			},
			{
				value: 106.2,
				label: '2018',
				options: {
					decimalPlaces: 1,
				},
			},

		],
	}),
}
</script>

<style scoped lang="scss">

</style>
